@header-height: 40px; // 导航栏高度
@menu-tags-height: 30px; // 菜单标签栏高度
@warning-color-hover: #ffc53d;

img {
  -webkit-user-drag: none;
}

.public-ellipsis {
  display: inline-block; // -webkit-box;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-line-clamp: 1; // 默认一行缩略
}

// 禁止用户选中文字
.public-user-select {
  user-select: none;
  //user-select: none;
  //user-select: none;
}

.public-card-hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f,
  0 5px 12px 4px #00000017;
}

.total-red td {
  background-color: #efefef !important;
}

.ant-back-top {
  bottom: 100px !important;
  right: 24px !important;
}

.custom-select {
  font-size: 12px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 20px !important;
}

.upload-list-box {
  .compatible-upload-list {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .compatible-upload-list-text {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.innerHtml {
  p {
    margin-bottom: 0 !important;
  }
}
@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);